// import { MenusAPI, RolePermissionsAPI } from "api";
// import React, { useEffect, useState } from "react";
// import { Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";
// import { FormProvider, useFormContext } from "react-hook-form";

// import Accordion from "react-bootstrap/Accordion";
// import { useLocation, useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
// // import { useFetchMenus } from "hooks/useFetchMenu";
// import { customSettings } from "utils/customConfig";

// const AddPermissionsBasicInformation = () => {
//   const { register } = useFormContext();
//   const location = useLocation();
//   const navigate = useNavigate();
//   const [collapseState, setCollapseState] = useState(false);
//   const [modulePermissions, setModulePermissions] = useState([]);

//   const menuData = JSON.parse(localStorage.getItem("menus"));

//   // Separate parent and child menu items
//   const parents = menuData?.filter((menu) => menu.parentId === 0);
//   const children = menuData?.filter((menu) => menu.parentId !== 0);

//   const sortedParents = parents?.sort((a, b) => a.sortOrder - b.sortOrder);
//   // Map children to their respective parents
//   const nestedData = sortedParents?.map((parent) => {
//     return {
//       ...parent,
//       children: children
//         .filter((child) => child.parentId === parent.id)
//         .sort((a, b) => a.sortOrder - b.sortOrder), // Sort children based on sortOrder,
//     };
//   });

//   console.log("nestedData", nestedData);

//   const handlePermissionChange = (moduleId, permissionType) => (event) => {
//     const isChecked = event.target.checked;

//     // Check if there's an existing permission object for the module
//     const existingPermission = modulePermissions.find(
//       (permission) => permission.menuId === moduleId
//     );
//     if (existingPermission) {
//       // Update the existing permission object
//       const updatedPermission = {
//         ...existingPermission,
//         [permissionType]: isChecked,
//       };
//       setModulePermissions((prevPermissions) =>
//         prevPermissions.map((permission) =>
//           permission.menuId === moduleId ? updatedPermission : permission
//         )
//       );
//     } else {
//       // Create a new permission object
//       const newPermission = {
//         roleId: location?.state ? location?.state?.id : 0,
//         menuId: moduleId,
//         allowView: false,
//         allowDelete: false,
//         allowUpdate: false,
//         allowNew: false,
//         [permissionType]: isChecked,
//       };
//       setModulePermissions((prevPermissions) => [
//         ...prevPermissions,
//         newPermission,
//       ]);
//     }
//   };

//   const handleSubmit = async (e) => {
//     try {
//       await RolePermissionsAPI.post(modulePermissions);
//       toast.success("Posted", {
//         position: customSettings.toastPosition,
//         autoClose: customSettings.toastClose,
//       });
//       navigate(-1);
//     } catch (error) {
//       toast.error("Something Wrong", {
//         position: customSettings.toastPosition,
//         autoClose: customSettings.toastClose,
//       });
//       console.log(error);
//     }
//   };

//   console.log("modulePermissions", modulePermissions);

//   return (
//     <Card className="mb-3">
//       <Card.Header as="h6" className="bg-body-tertiary">
//         {/* Basic information */}
//         <div className="row ">
//           <div className="col">Module Name</div>
//           <div className="col">Feature</div>
//           <div className="col d-flex gap-5">
//             <div>View</div>
//             <div>Add</div>
//             <div>Edit</div>
//             <div>Delete</div>
//           </div>
//         </div>
//       </Card.Header>
//       <Card.Body className="p-0">
//         {nestedData?.map((data, index) => (
//           <Accordion className="pb-1">
//             <Accordion.Item eventKey={index} className="h-1">
//               <Accordion.Header>{data.name}</Accordion.Header>
//               <Accordion.Body>
//                 <div className="py-2">
//                   {data.children.map((menu) => (
//                     // console.log(menu),
//                     <>
//                       <div className="row " key={menu.id}>
//                         <h6 className="col"></h6>
//                         <h6 className="col">{menu.name}</h6>

//                         <h6 className="col d-flex gap-5">
//                           <Form.Check
//                             type="checkbox"
//                             id="defaultCheckbox"
//                             label=""
//                             name="allowView"
//                             onChange={handlePermissionChange(
//                               menu.id,
//                               "allowView"
//                             )}
//                             checked={
//                               modulePermissions.find((d) => d.menuId == menu.id)
//                                 ?.allowView || false
//                             }

//                             // onChange={(e) => handlePermissions(e, menu)}
//                           />
//                           <Form.Check
//                             type="checkbox"
//                             id="defaultCheckbox"
//                             label=""
//                             // onChange={(e) => handlePermissions(e, menu)}
//                             onChange={handlePermissionChange(
//                               menu.id,
//                               "allowNew"
//                             )}
//                             name="allowNew"
//                             checked={
//                               modulePermissions.find((d) => d.menuId == menu.id)
//                                 ?.allowNew || false
//                             }
//                           />
//                           <Form.Check
//                             type="checkbox"
//                             id="defaultCheckbox"
//                             label=""
//                             // onChange={(e) => handlePermissions(e, menu)}
//                             onChange={handlePermissionChange(
//                               menu.id,
//                               "allowUpdate"
//                             )}
//                             name="allowUpdate"
//                             checked={
//                               modulePermissions.find((d) => d.menuId == menu.id)
//                                 ?.allowUpdate || false
//                             }
//                           />
//                           <Form.Check
//                             type="checkbox"
//                             id="defaultCheckbox"
//                             label=""
//                             // onChange={(e) => handlePermissions(e, menu)}
//                             onChange={handlePermissionChange(
//                               menu.id,
//                               "allowDelete"
//                             )}
//                             name="allowDelete"
//                             checked={
//                               modulePermissions.find((d) => d.menuId == menu.id)
//                                 ?.allowDelete || false
//                             }
//                           />
//                         </h6>
//                       </div>
//                     </>
//                   ))}
//                 </div>
//               </Accordion.Body>
//             </Accordion.Item>
//           </Accordion>
//         ))}
//       </Card.Body>
//     </Card>
//   );
// };

// export default AddPermissionsBasicInformation;

import { MenusAPI, RolePermissionsAPI } from "api";
import React, { useEffect, useState } from "react";
import { Card, Form, Accordion } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { customSettings } from "utils/customConfig";

const AddPermissionsBasicInformation = () => {
  const { register } = useFormContext();
  const location = useLocation();
  const navigate = useNavigate();
  const [modulePermissions, setModulePermissions] = useState([]);

  // Fetch menu data from localStorage
  const menuData = JSON.parse(localStorage.getItem("menus")) || [];

  // Separate parent and child menu items
  const parents = menuData.filter((menu) => menu.parentId === 0);
  const children = menuData.filter((menu) => menu.parentId !== 0);

  // Sort parents and map children to their respective parents
  const sortedParents = parents.sort((a, b) => a.sortOrder - b.sortOrder);
  const nestedData = sortedParents.map((parent) => ({
    ...parent,
    children: children
      .filter((child) => child.parentId === parent.id)
      .sort((a, b) => a.sortOrder - b.sortOrder),
  }));

  // Handle permission checkbox changes
  const handlePermissionChange = (moduleId, permissionType) => (event) => {
    const isChecked = event.target.checked;

    setModulePermissions((prevPermissions) => {
      const existingPermission = prevPermissions.find(
        (permission) => permission.menuId === moduleId
      );

      if (existingPermission) {
        return prevPermissions.map((permission) =>
          permission.menuId === moduleId
            ? { ...permission, [permissionType]: isChecked }
            : permission
        );
      } else {
        return [
          ...prevPermissions,
          {
            roleId: location?.state?.id || 0,
            menuId: moduleId,
            allowList: false,
            allowView: false,
            allowDelete: false,
            allowUpdate: false,
            allowNew: false,
            [permissionType]: isChecked,
          },
        ];
      }
    });
  };

  // Handle form submission
  const handleSubmit = async () => {
    try {
      await RolePermissionsAPI.post(modulePermissions);
      toast.success("Permissions saved successfully!", {
        position: customSettings.toastPosition,
        autoClose: customSettings.toastClose,
      });
      navigate(-1); // Navigate back
    } catch (error) {
      toast.error("Failed to save permissions. Please try again.", {
        position: customSettings.toastPosition,
        autoClose: customSettings.toastClose,
      });
      console.error("Error saving permissions:", error);
    }
  };

  // console.log("modulePermissions", modulePermissions);

  return (
    <Card className="mb-3">
      <Card.Header as="h6" className="bg-body-tertiary">
        <div className="row">
          <div className="col-md-3">Module Name</div>
          <div className="col-md-4">Feature</div>
          <div className="col-md-4 d-flex justify-content-between ">
            <span>List</span>
            <span>View</span>
            <span>Add</span>
            <span>Edit</span>
            <span>Delete</span>
          </div>
        </div>
      </Card.Header>
      <Card.Body className="p-0">
        <Accordion>
          {nestedData.map((data, index) => (
            <Accordion.Item key={data.id} eventKey={index.toString()}>
              <Accordion.Header>
                <span className="fs-10 fw-medium">{data.name}</span>
              </Accordion.Header>
              <Accordion.Body>
                {data.children.map((menu) => (
                  <div key={menu.id} className="row py-2 align-items-center">
                    <div className="col-md-3"></div>
                    <div className="col-md-4 fs-10 fw-medium">{menu.name}</div>
                    <div className="col-md-4 d-flex justify-content-between">
                      {[
                        "allowList",
                        "allowView",
                        "allowNew",
                        "allowUpdate",
                        "allowDelete",
                      ].map((permission) => (
                        <Form.Check
                          key={permission}
                          type="checkbox"
                          id={`${menu.id}-${permission}`}
                          label=""
                          name={permission}
                          onChange={handlePermissionChange(menu.id, permission)}
                          checked={
                            modulePermissions.find(
                              (d) => d.menuId === menu.id
                            )?.[permission] || false
                          }
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </Card.Body>
    </Card>
  );
};

export default AddPermissionsBasicInformation;
