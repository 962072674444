import axios from "axios";
import FalconLightBox from "components/common/FalconLightBox";
import Flex from "components/common/Flex";
import QuillEditor from "components/common/QuillEditor";
import SubtleBadge from "components/common/SubtleBadge";
import { useFetchCategoriesGroup } from "hooks/useFetchCategoriesGroup";
import { useFetchPageGroups } from "hooks/useFetchPagesGroup";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Image, Row } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { useFormContext } from "react-hook-form";
import { FaImage } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { BASEURL, customSettings } from "utils/customConfig";
import CustomOption from "utils/CustomOption";

const PageGroupBasicInformation = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const {
    register,
    setValue,
    control,
    watch,
    clearErrors,
    getValues,
    formState: { errors },
  } = useFormContext();

  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);

  const maxCharactersLength = 255;

  const { data: pageGroupsData, isLoading: pageGroupsLoading } =
    useFetchPageGroups();

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      "image/*": [".jpeg", ".png", ".jpg"],
    },
    onDrop: async (acceptedFiles) => {
      const uploadedFile = acceptedFiles[0];
      const uploadedFileUrl = await uploadFile(uploadedFile);

      setValue("backgroundImage", uploadedFileUrl);

      setFile(
        Object.assign(uploadedFile, {
          preview: URL.createObjectURL(uploadedFile),
        })
      );
    },
  });

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      setLoading(true);
      const response = await axios.post("/Catalogue/CategoryFile", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setLoading(false);

      // Assuming the response contains the URL of the uploaded file
      return response.data[0].destination;
    } catch (error) {
      console.error("File upload failed:", error);
      toast.error("Failed to File Upload", {
        position: customSettings.toastPosition,
        autoClose: customSettings.toastClose,
      });
      return "";
    }
  };

  return (
    <>
      {pageGroupsLoading ? (
        <div className="d-flex py-3 justify-content-center align-items-center">
          <Button variant="falcon-default" size="sm" className="fs-10">
            Loading...
          </Button>
        </div>
      ) : (
        <Card.Body
          className=""
          style={{
            height: `calc(100vh - 12.7rem)`,
            overflow: "auto",
            padding: "5px 20px",
          }}
        >
          <Row className="gx-2 pb-2">
            <Col md="6">
              <Form.Group>
                {/* <FloatingLabel controlId="floatingInput" label="Name"> */}
                <Form.Control
                  type="text"
                  placeholder="Name"
                  title="Name"
                  maxLength={maxCharactersLength}
                  className="py-2 "
                  isInvalid={!!errors.name}
                  {...register("name")}
                />
                <div className="d-flex justify-content-between align-items-center">
                  <Form.Control.Feedback
                    type="invalid"
                    className="d-inline w-75"
                  >
                    {errors.name?.message}
                  </Form.Control.Feedback>
                  {/* <small className="fs-10 text-muted">
                    {`${titleWatch?.length || 0} / ${maxCharactersLength}`}
                  </small> */}
                </div>
                {/* <Form.Control.Feedback type="invalid">
                  {errors.title?.message}
                </Form.Control.Feedback> */}
                {/* </FloatingLabel> */}
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Group>
                {/* <FloatingLabel controlId="floatingInput" label="Name"> */}
                <Form.Control
                  type="text"
                  placeholder="Description"
                  title="Description"
                  maxLength={maxCharactersLength}
                  className="py-2 "
                  isInvalid={!!errors.description}
                  {...register("description")}
                />
                <div className="d-flex justify-content-between align-items-center">
                  <Form.Control.Feedback
                    type="invalid"
                    className="d-inline w-75"
                  >
                    {errors.description?.message}
                  </Form.Control.Feedback>
                  {/* <small className="fs-10 text-muted">
                    {`${authorWatch?.length || 0} / ${maxCharactersLength}`}
                  </small> */}
                </div>
                {/* <Form.Control.Feedback type="invalid">
                  {errors.author?.message}
                </Form.Control.Feedback> */}
                {/* </FloatingLabel> */}
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Group>
                {/* <FloatingLabel controlId="floatingInput" label="Name"> */}
                <Form.Control
                  type="number"
                  placeholder="Display Order"
                  title="Display Order"
                  className="py-2 mb-1"
                  isInvalid={!!errors.sortOrder}
                  {...register("sortOrder")}
                  onChange={(e) => {
                    clearErrors("sortOrder");
                    const value = e.target.value;
                    // Allow only positive integers or empty
                    if (value === "" || /^[0-9]\d*$/.test(value)) {
                      e.target.value = value; // Set the value if it's valid
                    } else {
                      e.target.value = ""; // Reset if invalid
                    }
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.sortOrder?.message}
                </Form.Control.Feedback>
                {/* </FloatingLabel> */}
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Group>
                {/* <FloatingLabel controlId="floatingInput" label="Name"> */}
                <Form.Control
                  type="text"
                  placeholder="Url"
                  title="Url"
                  maxLength={maxCharactersLength}
                  className="py-2"
                  isInvalid={!!errors.url}
                  {...register("url")}
                />
                <div className="d-flex justify-content-between align-items-center">
                  <Form.Control.Feedback
                    type="invalid"
                    className="d-inline w-75"
                  >
                    {errors.url?.message}
                  </Form.Control.Feedback>
                  {/* <small className="fs-10 text-muted">
                    {`${linkWatch?.length || 0} / ${maxCharactersLength}`}
                  </small> */}
                </div>

                {/* </FloatingLabel> */}
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group controlId="layout">
                <Form.Select
                  aria-label="options"
                  isInvalid={!!errors.layout}
                  {...register(`layout`)}
                >
                  <option value="">Select</option>

                  <option value="List">List</option>
                  <option value="Card">Card</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.layout?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md="6">
              <div className="d-flex justify-content-between">
                <div className="d-flex gap-3">
                  <div {...getRootProps()}>
                    <input {...getInputProps({ multiple: false })} />
                    <Flex
                      justifyContent="center"
                      alignItems="center"
                      // className="px-4"
                    >
                      <FaImage
                        size={27}
                        title="Image"
                        className="mt-2 cursor-pointer mr-1"
                      />
                      {/* <p className="fs-9 mb-0 text-700"> Select Logo</p> */}
                    </Flex>
                  </div>

                  {/* <Col md={file || (state?.logoFile?.fileBase64 ? "2" : "1")}> */}
                  {file || state?.backgroundImageRelativeUrl ? (
                    <FalconLightBox
                      image={
                        file
                          ? file.preview
                          : `${BASEURL}${state?.backgroundImageRelativeUrl}`
                      }
                    >
                      <Image
                        src={
                          file
                            ? file.preview
                            : `${BASEURL}${state?.backgroundImageRelativeUrl}`
                        }
                        rounded
                        width={80}
                        height={40}
                      />
                    </FalconLightBox>
                  ) : null}
                  {/* Render nothing if both file and state.logoFile.fileBase64 are not present */}
                </div>
              </div>
            </Col>
            <Col md="6">
              <Form.Check
                type="checkbox"
                id="defaultSwitch"
                label="Active"
                className="mt-2"
                defaultChecked={!state}
                isInvalid={!!errors.status}
                {...register("status")}
              />
            </Col>
            {/* 
            <Col md={12}>
              <QuillEditor
                placeholder="Content"
                setValue={(value) => setValue("contentDetail", value)} // Sync with react-hook-form
                value={watch("contentDetail")} // Get the value from react-hook-form
              />
            </Col> */}
          </Row>
        </Card.Body>
      )}
    </>
  );
};

export default PageGroupBasicInformation;
