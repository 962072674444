import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { AgGridReact } from "ag-grid-react"; // AG Grid Component
import { CustomersEstimatesAPI } from "api";
import TableHeaderDropdown from "components/common/TableHeaderDropdown";
import { settings } from "config";
import React, { useCallback, useRef, useState } from "react";
import { Button, Card, Dropdown, Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { CONSTANT_ROUTES } from "routes";
import {
  resetColumnState,
  restoreColumnState,
  saveColumnState,
} from "utils/tablesUtils";

export default function CustomerEstimatesTable({ data }) {
  let isDarkBool = localStorage.getItem("isDark") === true;
  let isDarkTheme = localStorage.getItem("theme") === "dark";
  let auto = localStorage.getItem("theme") === "auto";
  const navigate = useNavigate();
  const { state } = useLocation();
  const columnApiRef = useRef();

  const [colDefs, setColDefs] = useState([
    {
      field: "categoryGroupName",
      headerName: "Category Group Name",
      hide: false,
      flex: 3,
    },
    {
      field: "planName",
      headerName: "Plan Name",
      hide: false,
      flex: 3,
    },

    {
      field: "createdOn",
      headerName: "Date",
      hide: false,
      flex: 1.5,
      cellRenderer: (p) => {
        const date = p?.data?.createdOn
          ? new Date(p.data.createdOn).toLocaleDateString()
          : "";
        return <div>{date}</div>;
      },
    },
    {
      field: "question6",
      headerName: "Carry Out Work",
      hide: false,
      flex: 1.5,
    },

    {
      field: "planPrice",
      headerName: "Plan Price",
      hide: false,
      flex: 1.5,
    },
    {
      field: "estimateStatus",
      headerName: "Estimate Status",
      hide: false,
      flex: 1.5,
    },
  ]);

  const handleRowClick = (data) => {
    if (data) {
      navigate(`${CONSTANT_ROUTES.CUSTOMER_ESTIMATE_DETAILS}`, {
        state: data,
      });
    }
  };

  const defaultColDef = {
    sortable: true,
    filter: true,
    // floatingFilter: true,
    // flex: 1,
    // rowDrag: true,
  };

  //   ===============Custom Api Call start================

  let gridApi;
  let columnApi;

  const onGridReady = useCallback(
    (e) => {
      gridApi = e.api;
      columnApi = e.columnApi;
      restoreState();

      CustomersEstimatesAPI.get({ params: { userId: data } }).then((res) => {
        e.api.applyTransaction({ add: res?.data?.data });
      });
    },
    [window]
  );

  const saveState = useCallback(() => {
    saveColumnState("customerEstimates", columnApiRef);
  }, []);

  const restoreState = useCallback(() => {
    restoreColumnState("customerEstimates", columnApiRef);
  }, []);

  const resetState = useCallback(() => {
    resetColumnState("customerEstimates", columnApiRef);
  }, []);

  const onFilterTextBoxChanged = useCallback(() => {
    columnApiRef.current.api.setGridOption(
      "quickFilterText",
      document.getElementById("filter-text-box").value
    );
  }, []);

  return (
    <div>
      {/* <div className="py-2">
        <BreadCrumb />
      </div> */}

      <div
        className={`${
          isDarkBool || isDarkTheme || auto
            ? "ag-theme-quartz-dark"
            : "ag-theme-quartz"
        } rounded-0`} // applying the grid theme
        // style={{ height: "100vh" }} // the grid will fill the size of the parent container
      >
        <Card style={{ height: `calc(100vh - 10.7rem)`, overflow: "auto" }}>
          <div className="d-flex justify-content-between align-items-center mb-2 mt-2">
            <h5 className="fs-9 mb-0 text-nowrap py-xl-0">
              {" "}
              {/* Customers | {data || "Detail"} | Estimates */}
            </h5>
            <div className="d-flex align-items-center gap-2">
              <Form className="position-relative">
                <FontAwesomeIcon
                  icon="search"
                  className="position-absolute text-400 search-box-icon"
                  style={{
                    left: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                />
                <Form.Control
                  type="search"
                  placeholder="Search..."
                  style={{
                    paddingLeft: "30px",
                  }}
                  aria-label="Search"
                  id="filter-text-box"
                  className="rounded-pill search-input"
                  onInput={onFilterTextBoxChanged}
                />
              </Form>
              {/* <Button
                variant="link"
                className="icon-item icon-item-sm icon-item-hover shadow-none p-0 me-1 ms-md-2"
                onClick={() => navigate(-1)}
              >
                <FontAwesomeIcon icon="arrow-left" />
              </Button> */}
              {/* <OverlayTrigger
                overlay={
                  <Tooltip
                    style={{ position: "fixed" }}
                    id="overlay-trigger-example"
                  >
                    New
                  </Tooltip>
                }
              >
                <IconButton
                  variant="falcon-default"
                  size="sm"
                  icon="plus"
                  transform="shrink-3"
                  onClick={() =>
                    navigate(CONSTANT_ROUTES.ESTIMATES_TABLE_DETAILS)
                  }
                >
                  <span className="d-none d-sm-inline-block ms-1">New</span>
                </IconButton>
              </OverlayTrigger> */}
              <TableHeaderDropdown>
                <div className="py-2">
                  <Dropdown.Item
                    onClick={saveState}
                    className="d-flex align-items-center gap-2 py-2"
                  >
                    Save State
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={restoreState}
                    className="d-flex align-items-center gap-2 py-2"
                  >
                    Restore State
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={resetState}
                    className="d-flex align-items-center gap-2 py-2"
                  >
                    Reset State
                  </Dropdown.Item>
                </div>
              </TableHeaderDropdown>
            </div>
          </div>

          <Card.Body className="p-0">
            <AgGridReact
              // rowData={rowData}
              ref={columnApiRef}
              pagination
              paginationPageSize={settings.paginationSize}
              onGridReady={onGridReady}
              columnDefs={colDefs}
              defaultColDef={defaultColDef}
              headerHeight={35}
              rowHeight={35}
              onRowDoubleClicked={(params) => handleRowClick(params.data)}
              overlayNoRowsTemplate="Nothing found - Looks empty over here"
            />
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}
