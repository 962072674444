import axios from "axios";
import FalconLightBox from "components/common/FalconLightBox";
import Flex from "components/common/Flex";
import QuillEditor from "components/common/QuillEditor";
import React, { useState } from "react";
import { Button, Card, Col, Form, Image, Row } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { useFormContext } from "react-hook-form";
import { FaImage } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BASEURL, customSettings } from "utils/customConfig";

const SliderInformation = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const {
    register,
    setValue,
    reset,
    control,
    watch,
    clearErrors,
    getValues,
    setError,
    formState: { errors },
  } = useFormContext();

  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      setLoading(true);
      const response = await axios.post("/Site/UploadFile", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setLoading(false);

      // Assuming the response contains the URL of the uploaded file
      return response.data[0].destination;
    } catch (error) {
      console.error("File upload failed:", error);
      toast.error("Failed to File Upload", {
        position: customSettings.toastPosition,
        autoClose: customSettings.toastClose,
      });
      return "";
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      "image/*": [".jpeg", ".png", ".jpg"],
    },
    onDrop: async (acceptedFiles) => {
      const uploadedFile = acceptedFiles[0];
      const uploadedFileUrl = await uploadFile(uploadedFile);

      setValue("imageUrl", uploadedFileUrl);

      setFile(
        Object.assign(uploadedFile, {
          preview: URL.createObjectURL(uploadedFile),
        })
      );
    },
  });

  return (
    <>
      {loading ? (
        <div className="d-flex py-3 justify-content-center align-items-center">
          <Button variant="falcon-default" size="sm" className="fs-10">
            Loading...
          </Button>
        </div>
      ) : (
        <Card.Body
          className=""
          style={{
            height: `calc(100vh - 12.7rem)`,
            overflow: "auto",
            padding: "5px 20px",
          }}
        >
          <Row className="gx-2 pb-2">
            <Col md="6">
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Caption"
                  title="Caption"
                  className="py-2 "
                  isInvalid={!!errors.caption}
                  {...register("caption")}
                />
                <div className="d-flex justify-content-between align-items-center">
                  <Form.Control.Feedback
                    type="invalid"
                    className="d-inline w-75"
                  >
                    {errors.caption?.message}
                  </Form.Control.Feedback>
                  {/* <small className="fs-10 text-muted">
                    {`${titleWatch?.length || 0} / ${maxCharactersLength}`}
                  </small> */}
                </div>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Alt Text"
                  title="Alt Text"
                  className="py-2 "
                  isInvalid={!!errors.altText}
                  {...register("altText")}
                />
                <div className="d-flex justify-content-between align-items-center">
                  <Form.Control.Feedback
                    type="invalid"
                    className="d-inline w-75"
                  >
                    {errors.altText?.message}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="URL"
                  title="URL"
                  className="py-2 "
                  isInvalid={!!errors.url}
                  {...register("url")}
                />
                <div className="d-flex justify-content-between align-items-center">
                  <Form.Control.Feedback
                    type="invalid"
                    className="d-inline w-75"
                  >
                    {errors.url?.message}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Animation"
                  title="Animation"
                  className="py-2 "
                  isInvalid={!!errors.animation}
                  {...register("animation")}
                />
                <div className="d-flex justify-content-between align-items-center">
                  <Form.Control.Feedback
                    type="invalid"
                    className="d-inline w-75"
                  >
                    {errors.animation?.message}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                {/* <FloatingLabel controlId="floatingInput" label="Name"> */}
                <Form.Control
                  type="number"
                  placeholder="Display Order"
                  title="Display Order"
                  className="py-2 mb-1"
                  isInvalid={!!errors.sortOrder}
                  {...register("sortOrder")}
                  onChange={(e) => {
                    // clearErrors("sortOrder");
                    const value = e.target.value;
                    // Allow only positive integers or empty
                    if (value === "" || /^[0-9]\d*$/.test(value)) {
                      e.target.value = value; // Set the value if it's valid
                    } else {
                      e.target.value = ""; // Reset if invalid
                    }
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.sortOrder?.message}
                </Form.Control.Feedback>
                {/* </FloatingLabel> */}
              </Form.Group>
            </Col>

            <Col md="6">
              <div className="d-flex gap-3">
                <div {...getRootProps()}>
                  <input {...getInputProps({ multiple: false })} />
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    // className="px-4"
                  >
                    <FaImage
                      size={27}
                      title="Image"
                      className="mt-2 cursor-pointer mr-1"
                    />
                    {/* <p className="fs-9 mb-0 text-700"> Select Logo</p> */}
                  </Flex>
                </div>

                {file || state?.imageRelativeUrl ? (
                  <FalconLightBox
                    image={
                      file
                        ? file.preview
                        : `${BASEURL}${state.imageRelativeUrl}`
                    }
                  >
                    <Image
                      src={
                        file
                          ? file.preview
                          : `${BASEURL}${state.imageRelativeUrl}`
                      }
                      rounded
                      width={80}
                      height={40}
                    />
                  </FalconLightBox>
                ) : null}
              </div>
            </Col>

            <Col md={12}>
              <QuillEditor
                placeholder="Description"
                setValue={(value) => setValue("description", value)} // Sync with react-hook-form
                value={watch("description")} // Get the value from react-hook-form
              />
            </Col>
            <Col md={6}>
              <Form.Check
                type="checkbox"
                id="defaultSwitch"
                label="Active"
                className="mt-2"
                defaultChecked={!state}
                isInvalid={!!errors.status}
                {...register("status")}
              />
            </Col>
          </Row>
        </Card.Body>
      )}
    </>
  );
};

export default SliderInformation;
