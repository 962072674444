import { yupResolver } from "@hookform/resolvers/yup";
import { PagesAPI, CategoryFieldSetsAPI, VariantsAPI, PageGroupAPI } from "api";
import BreadCrumb from "components/breadcrumb/BreadCrumb";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { customSettings } from "utils/customConfig";
import * as yup from "yup";
import PageGroupBasicInformation from "./PageGroupBasicInformation";
import moment from "moment";

const schema = yup
  .object({
    name: yup.string().required("Name is required"),
    description: yup.string().required("Description is required"),
    sortOrder: yup.string().required("Display order is required"),
    backgroundImage: yup.string(),
    layout: yup.string().required("Layout is required"),
    url: yup.string().required("Url is required"),
    status: yup.boolean(),
  })
  .required();

const AddPageGroup = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const submittedValues = {};
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });
  const { handleSubmit, reset, setValue } = methods;

  const onSubmit = async (data) => {
    let myData = {
      ...data,
      id: location?.state?.id ?? 0, // Use nullish coalescing to handle undefined or null
    };

    console.log(myData);

    try {
      if (location?.state) {
        setLoading(true);
        await PageGroupAPI.post(myData);
        setLoading(false);
        toast.success("Page Group Updated Successfully", {
          position: customSettings.toastPosition,
          autoClose: customSettings.toastClose,
        });
      } else {
        setLoading(true);
        await PageGroupAPI.post(myData);
        setLoading(false);
        toast.success("Page Group Created Successfully", {
          position: customSettings.toastPosition,
          autoClose: customSettings.toastClose,
        });
      }

      // ------- Get all object keys form data and set empty values to reset ------------
      const keys = Object.keys(data);
      for (const key of keys) {
        submittedValues[key] = "";
      }
      reset({ ...submittedValues });
      navigate(-1);
    } catch (error) {
      toast.error("Something Went Wrong", {
        position: customSettings.toastPosition,
        autoClose: customSettings.toastClose,
      });
      console.log(error);
    }
  };

  useEffect(() => {
    if (location.state) {
      reset(location.state);
    }
  }, []);

  return (
    <>
      {/* <div className="py-2">
        <BreadCrumb />
      </div> */}
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <Card.Header className="border-bottom">
              <Row className="flex-between-center ">
                <Col md>
                  <h5 className="fs-9 text-nowrap mb-2 mb-md-0">
                    {" "}
                    Page Groups
                  </h5>
                </Col>
                <Col xs="auto">
                  <Button
                    variant="link"
                    className="text-secondary fw-medium p-0 me-3"
                    type="button"
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </Button>
                  <Button variant="primary" type="submit" disabled={loading}>
                    Ok
                  </Button>
                </Col>
              </Row>
            </Card.Header>
            <PageGroupBasicInformation />
            {/* <Row className="">
            
              <Col lg={12}>
              </Col> */}
            {/* <Col xs={12}>
    
            </Col> */}
            {/* </Row> */}
          </Card>
        </Form>
      </FormProvider>
    </>
  );
};

export default AddPageGroup;
