import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React, { useState } from "react";
import { GoGear } from "react-icons/go";
import { RiDeleteBinLine } from "react-icons/ri";

const SortableItem = ({
  id,
  children,
  onDelete,
  onApplyProperties,
  handleSetting,
  field,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    position: "relative",
    zIndex: isHovered ? 1 : 0, // Ensuring the hover buttons are above other elements
  };

  const handleDeleteClick = (event) => {
    event.preventDefault(); // Prevents triggering drag when clicking delete
    onDelete(id);
  };

  const handleApplyPropertiesClick = (event) => {
    event.preventDefault(); // Prevents triggering drag when clicking apply CSS
    console.log(field, id);
    handleSetting(field);
    onApplyProperties(id);
  };

  return (
    <>
      <div
        ref={setNodeRef}
        style={style}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div
          {...(isFocused ? {} : attributes)}
          {...(isFocused ? {} : listeners)} // Disable drag listeners when input is focused
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        >
          {children}
        </div>
        {isHovered && (
          <div
            className="flex"
            style={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
          >
            <GoGear
              className="cursor-pointer"
              size={20}
              onClick={handleApplyPropertiesClick}
            />
            <RiDeleteBinLine
              className="cursor-pointer"
              size={20}
              onClick={handleDeleteClick}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default SortableItem;
