import React, { useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

import img1 from "../../../../assets/img/myImages/1.png";
import img2 from "../../../../assets/img/myImages/2.png";
import img3 from "../../../../assets/img/myImages/3.png";
import img4 from "../../../../assets/img/myImages/4.png";
import img5 from "../../../../assets/img/myImages/5.png";
import img6 from "../../../../assets/img/myImages/6.png";
import img7 from "../../../../assets/img/myImages/7.png";
import { FaClock, FaUser } from "react-icons/fa";
import { UpdateEstimatesAPI } from "api";
import { toast } from "react-toastify";
import { BASEURL, customSettings, PLANS } from "utils/customConfig";
import { formatDateWithCapitalizedMonth } from "utils/formatter";

import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css";

const EstimatesInformation = ({ setStatusValue, statusValue }) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [hoveredCard, setHoveredCard] = useState(null); // Track hovered card

  const [data, setData] = useState(state || {});

  // console.log(data);

  const [isOpen, setIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  // const handleImageView = (data) => {
  //   console.log(data);
  //   let d = `${BASEURL}/wwwroot/${data}`;
  //   setCurrentImage(d);
  //   setIsOpen(true); // Opens the lightbox when an image is clicked
  // };

  const handleImageView = (data) => {
    if (!data) {
      return;
    }
    // Determine file extension
    const fileExtension = data.split(".").pop()?.toLowerCase();

    // Define the full file URL
    let fileUrl = `${BASEURL}/wwwroot/${data}`;

    // Check if the file is an image
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp"];

    if (fileExtension && imageExtensions.includes(fileExtension)) {
      // If it's an image, set it as the current image and open lightbox
      setCurrentImage(fileUrl);
      setIsOpen(true);
    } else {
      // If it's a PDF or Word file, trigger download
      window.open(fileUrl, "_blank");

      // const link = document.createElement("a");
      // link.href = fileUrl;
      // link.download = data.split("/").pop() || "file"; // Extract file name from path
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);
    }
  };

  const handleStatusChange = (value) => {
    setData((prevData) => ({
      ...prevData,
      estimateStatus: value,
    }));
    setStatusValue(value);
  };

  // console.log("data", data);

  return (
    <>
      {loading ? (
        <div className="d-flex py-3 justify-content-center align-items-center">
          <Button variant="falcon-default" size="sm" className="fs-10">
            Loading...
          </Button>
        </div>
      ) : (
        <Card.Body
          className=""
          style={{
            height: `calc(100vh - 8.5rem)`,
            overflow: "auto",
            padding: "5px 20px",
          }}
        >
          {/* <div
            className="container d-flex align-items-center py-3"
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          >
            <div>
              <img src={img3} height={30} width={30} />
            </div>
            <div className="fw-bold fs-4 px-2">| Mes estimation</div>
          </div> */}

          <div className=" d-flex flex-column justify-content-between  gap-2 h-full">
            <div className="shadow card w-100 h-auto">
              <div className="d-flex text-black justify-content-between gap-2 align-items-center ">
                <div
                  style={{ width: "80%" }}
                  className="d-flex align-items-center gap-4"
                >
                  <span className="border p-2">
                    {data.createdOn
                      ? formatDateWithCapitalizedMonth(data.createdOn)
                      : "10 Octobre 2024"}
                  </span>
                  <div className=" p-2 text-end">
                    <FaClock size={20} />
                  </div>
                  <div className="border p-2">{data.question6}</div>
                </div>
                <div style={{ width: "20%" }}>
                  <select
                    onChange={(e) => handleStatusChange(e.target.value)}
                    value={data.estimateStatus || ""}
                    className="fs-12"
                    style={{
                      padding: "6px 4px",
                      width: "100%",
                      border: "1px solid rgb(235, 233, 233)",
                    }}
                  >
                    <option value="" disabled>
                      Status
                    </option>
                    <option value="Pending">Pending</option>
                    <option value="InProgress">In Progress</option>
                    <option value="Completed">Completed</option>
                  </select>
                </div>
              </div>
              <div className="d-flex text-black justify-content-between gap-2 align-items-center mt-2 ">
                <div className="border p-2 fw-bold" style={{ width: "80%" }}>
                  {data.planName}
                </div>
                <div
                  className="border p-2 fw-bold text-end"
                  style={{ width: "20%" }}
                >
                  {data.planPrice} {customSettings?.currencySign}
                </div>
              </div>
              <div className="d-flex text-black justify-content-between gap-2 align-items-center mt-2">
                <div className="border p-2" style={{ width: "80%" }}>
                  <LabelCard data={data.selectedOptions} />
                </div>
                <div className="border p-2 text-end" style={{ width: "20%" }}>
                  <PlanCard
                    data={data.selectedOptions}
                    viewInUltime={data?.planName === PLANS.ULTIMATE}
                    viewInpremium={data?.planName === PLANS.PREMIUM}
                  />
                </div>
              </div>
              <div className="d-flex text-black justify-content-between gap-2 align-items-center mt-2">
                <div className="border p-2" style={{ width: "59%" }}>
                  {data.streetAddress}
                </div>
                <div className="border p-2" style={{ width: "20%" }}>
                  {data.postalCode}
                </div>
                <div className="border p-2" style={{ width: "20%" }}>
                  {data.city}
                </div>
              </div>
              {/* <div className="d-flex text-black justify-content-between gap-2 align-items-center mt-2">
                <div className="border p-2" style={{ width: "20%" }}>
                  {data.postalCode}
                </div>
                <div className="border p-2" style={{ width: "20%" }}>
                  {data.city}
                </div>
              </div> */}
            </div>
            <div className="text-black card h-auto ">
              <Row className="g-2">
                <Col md={6} className="">
                  <div className="d-flex p-2 border justify-content-between align-items-center">
                    <div
                      className="d-flex  align-items-center gap-3"
                      style={{ width: "60%" }}
                    >
                      <div style={{ backgroundColor: "#f7f7f7" }}>
                        <img src={img3} height={30} width={30} />
                      </div>
                      <span>Type of accommodation</span>
                    </div>
                    <span className="border px-3 py-2" style={{ width: "40%" }}>
                      {data.question3}
                    </span>
                  </div>
                </Col>
                <Col md={6} className="">
                  <div className="d-flex  border p-2 justify-content-between align-items-center">
                    <div
                      className="d-flex  align-items-center gap-3"
                      style={{ width: "60%" }}
                    >
                      <div style={{ backgroundColor: "#f7f7f7" }}>
                        <FaUser size={30} />
                      </div>
                      <span>Property status</span>
                    </div>
                    <span className="border px-3 py-2" style={{ width: "40%" }}>
                      {data.question5}
                    </span>
                  </div>
                </Col>
                <Col md={6} className="">
                  <div className="d-flex border p-2  justify-content-between align-items-center">
                    <div
                      className="d-flex  align-items-center gap-3"
                      style={{ width: "60%" }}
                    >
                      <div style={{ backgroundColor: "#f7f7f7" }}>
                        <FaUser size={30} />
                      </div>
                      <span>You are</span>
                    </div>
                    <span className="border px-3 py-2" style={{ width: "40%" }}>
                      {data.question7}
                    </span>
                  </div>
                </Col>
                <Col md={6} className="">
                  <div className="d-flex border p-2  justify-content-between align-items-center">
                    <div
                      className="d-flex  align-items-center gap-3"
                      style={{ width: "60%" }}
                    >
                      <div style={{ backgroundColor: "#f7f7f7" }}>
                        <img src={img1} height={30} width={30} />
                      </div>
                      <span>Architect's plan</span>
                    </div>
                    <span
                      onClick={() => handleImageView(data.question1Document)}
                      className=" px-3 py-2"
                      style={{ width: "40%", cursor: "pointer" }}
                    >
                      {data.question1Document === ""
                        ? "No Document Found"
                        : "View"}
                    </span>
                  </div>
                </Col>
                <Col md={6} className="">
                  <div className="d-flex  border p-2  justify-content-between align-items-center">
                    <div
                      className="d-flex  align-items-center gap-3"
                      style={{ width: "60%" }}
                    >
                      <div style={{ backgroundColor: "#f7f7f7" }}>
                        <img src={img2} height={30} width={30} />
                      </div>
                      <span>Photos of works you like</span>
                    </div>
                    <span
                      onClick={() => handleImageView(data.question2Document)}
                      className="px-3 py-2"
                      style={{ width: "40%", cursor: "pointer" }}
                    >
                      {data.question2Document === ""
                        ? "No Document Found"
                        : "View"}
                    </span>
                  </div>
                </Col>
                <Col md={6} className="">
                  <div className="d-flex border p-2  justify-content-between align-items-center">
                    <div
                      className="d-flex  align-items-center gap-3"
                      style={{ width: "60%" }}
                    >
                      <div style={{ backgroundColor: "#f7f7f7" }}>
                        <img src={img5} height={30} width={30} />
                      </div>
                      <span>Interior designer</span>
                    </div>
                    <span className="border px-3 py-2" style={{ width: "40%" }}>
                      {data.question4}
                    </span>
                  </div>
                </Col>
                <Col md={6} className="">
                  <div className="d-flex border p-2  justify-content-between align-items-center">
                    <div
                      className="d-flex  align-items-center gap-3"
                      style={{ width: "60%" }}
                    >
                      <div style={{ backgroundColor: "#f7f7f7" }}>
                        <img src={img6} height={30} width={30} />
                      </div>
                      <span>Did you know us?</span>
                    </div>
                    <span className="border px-3 py-2" style={{ width: "40%" }}>
                      {data.question8}
                    </span>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Card.Body>
      )}
      {isOpen && (
        <Lightbox
          mainSrc={currentImage}
          onCloseRequest={() => setIsOpen(false)}
          reactModalStyle={{ overlay: { zIndex: 999999 } }}
          onImageLoad={() => {
            window.dispatchEvent(new Event("resize"));
          }}
        />
      )}
    </>
  );
};

export default EstimatesInformation;

export function getSelected(parent) {
  if (!parent?.isSelected) {
    return null;
  }

  const result = { ...parent }; // Create a shallow copy of the parent object

  if (parent.accordions) {
    result.accordions = {}; // Record Create a new Map for filtered accordions

    for (const key of Object.keys(parent.accordions)) {
      const values = parent.accordions[key]
        .map((item) => getSelected(item))
        .filter((item) => !!item);

      result.accordions[key] = values;
    }
  }

  if (parent.childrens) {
    result.childrens = parent.childrens
      .map((item) => getSelected(item))
      .filter((item) => !!item);
  }

  return result;
}

export const Children = ({ item }) => {
  // console.log("item", item)

  if (item.isCounter) {
    return (
      <>
        {Object.keys(item?.accordions)
          .map((key) => [key, item.accordions[key]])
          .map(([key, values]) => (
            <li key={key}>
              <div style={{ borderBottom: "1px solid #dfdfdf" }}>{key}</div>
              {values.length > 0 && (
                <ul style={{ paddingLeft: "1.1rem" }}>
                  {values.map((v, index) => (
                    <Children key={index} item={v} />
                  ))}
                </ul>
              )}
            </li>
          ))}
      </>
    );
  }
  return (
    <>
      <li>
        <div style={{ borderBottom: "1px solid #dfdfdf" }}>
          {item.fieldSets.length > 0 &&
            item.fieldSets.reduce((acc, item) => acc + item.prefix, "")}
          {item.name}
          {/* {item.fieldSets.length > 0 && item.fieldSets.reduce((acc, item) => acc + item.postfix, "")}
              &nbsp; */}{" "}
          {/* {!item.childrens.length > 0 &&
                  `(${item.fieldSets.reduce((acc, it) => acc + Number(it.value), 0)}${item.fieldSets.length > 0 && item.fieldSets.reduce((acc, item) => acc + `${item.postfix}`, "")})`
              } */}
          {!item.childrens.length > 0 &&
            `( ${item.fieldSets.reduce(
              (acc, it) => acc + Number(it.value),
              0
            )}${
              item.fieldSets.length > 0
                ? item.fieldSets.reduce(
                    (acc, it) => acc + (it.postfix ? ` ${it.postfix}` : ""),
                    ""
                  )
                : ""
            } )`}
        </div>
        {item.childrens.length > 0 && (
          <ul style={{ paddingLeft: "1.1rem" }}>
            {item.childrens.map((v, index) => (
              <Children item={v} key={index} />
            ))}
          </ul>
        )}
      </li>
    </>
  );
};

export const ValueChildren = ({
  item,
  forViewInLabel,
  viewInpremium = false,
  viewInUltime = false,
}) => {
  if (item.isCounter) {
    return (
      <>
        <li>
          <ul className="list-unstyled" style={{ textAlign: "right" }}>
            {Object.keys(item?.accordions)
              .map((key) => [key, item?.accordions[key]])
              .map(([key, values]) => (
                <li key={key}>
                  {values.length > 0 && (
                    <ul className="list-unstyled">
                      <div style={{ borderBottom: "1px solid #dfdfdf" }}>
                        &nbsp;
                      </div>
                      {values?.map((v, index) => (
                        <ValueChildren
                          viewInpremium={viewInpremium}
                          viewInUltime={viewInUltime}
                          key={index}
                          item={v}
                        />
                      ))}
                    </ul>
                  )}
                </li>
              ))}
          </ul>
        </li>
      </>
    );
  }
  return (
    <>
      <li className="list-unstyled">
        {item.fieldSets.map((d, index) => (
          <div
            key={index}
            style={{
              borderBottom: "1px solid #dfdfdf",
              paddingRight: "0px",
              textAlign: "right",
            }}
          >
            {forViewInLabel ? d.value : d.total.toFixed(2)}
          </div>
        ))}
        {item.childrens.length > 0 && (
          <ul key={Math.random() * 1000} className="list-unstyled">
            <div key={item} style={{ borderBottom: "1px solid #dfdfdf" }}>
              &nbsp;
            </div>
            {item?.childrens?.map((v, index) => (
              <>
                <ValueChildren
                  viewInpremium={viewInpremium}
                  viewInUltime={viewInUltime}
                  item={v}
                  key={index}
                />
              </>
            ))}
          </ul>
        )}
      </li>
    </>
  );
};

const parseJSON = (jsonString) => {
  try {
    const parsed = JSON.parse(jsonString); // Attempt to parse the JSON string
    return parsed; // Return the parsed JSON if successful
  } catch (error) {
    if (error instanceof SyntaxError) {
      console.error("Invalid JSON input:", error.message);
    } else {
      console.error("An unexpected error occurred:", error.message);
    }
    return null; // Return null or an appropriate fallback
  }
};
const LabelCard = ({ data, hoveredCard, lastStep }) => {
  let d = getSelected(parseJSON(data));
  // console.log(d);
  return (
    <>
      <div
        style={{
          border: "none",
          cursor: "pointer",
          overflow: "hidden",
          height: "auto",
        }}
      >
        <div className="card-body d-flex flex-column justify-content-between p-0">
          <div style={{ flexGrow: 1 }}>
            <p
              className="card-text"
              style={{ whiteSpace: "pre-wrap", lineHeight: "1.6" }}
            >
              <ul style={{ paddingLeft: "0px" }} className="list-unstyled">
                <li>
                  <div style={{ borderBottom: "1px solid #dfdfdf" }}>
                    {/* Masion */}
                    {d?.name}
                  </div>
                  <ul style={{ paddingLeft: "20px" }}>
                    {d?.childrens.map((dc, index) => (
                      <Children item={dc} key={index} />
                    ))}
                  </ul>
                </li>
              </ul>
            </p>
          </div>
          {/* <h6 className="border-top" style={{ textAlign: "right", paddingRight: "20px", paddingBottom: "7px", paddingTop: "10px" }}>
                  Total
              </h6> */}
        </div>
      </div>
    </>
  );
};

export const PlanCard = ({
  data,
  title,
  planType,
  isSelected,
  total,
  setHoveredCard = () => {}, // Default fallback
  lastStep,
  handlePlanSelection,
  hoveredCard,
  backgroundColor,
  iconColor,
  viewInpremium = false,
  viewInUltime = false,
}) => {
  let d = getSelected(parseJSON(data));
  return (
    <div
      style={{
        cursor: "pointer",
        position: "relative",
        overflow: "hidden",
        height: "auto",
      }}
    >
      <div className="card-body d-flex flex-column justify-content-between p-0">
        <div style={{ flexGrow: 1 }}>
          <p
            className="card-text"
            style={{ whiteSpace: "pre-wrap", lineHeight: "1.6" }}
          >
            <ul className="list-unstyled" style={{ paddingRight: "0px" }}>
              <li>
                <div style={{ borderBottom: "1px solid #dfdfdf" }}>&nbsp;</div>
                <ul className="list-unstyled text-end">
                  {d?.childrens.map((d, index) => (
                    <ValueChildren
                      estimatePage={true}
                      item={d}
                      key={index}
                      viewInpremium={viewInpremium}
                      viewInUltime={viewInUltime}
                    />
                  ))}
                </ul>
              </li>
            </ul>
          </p>
        </div>
      </div>
    </div>
  );
};
