import { useQuery } from "@tanstack/react-query";
import { PageGroupsAPI } from "api";

const fetchData = () => {
  return PageGroupsAPI.get();
};
export const useFetchPageGroups = () => {
  return useQuery({
    queryKey: ["page-groups"],
    queryFn: fetchData,
    refetchOnWindowFocus: false, // Disable refetching on window focus
  });
};
