import React, { useEffect, useState } from "react";
import { Button, Card, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import ContactsInformation from "./ContactsInformation";
import { useNavigate } from "react-router-dom";
import useQuery from "hooks/useQuery";
import { useLocation } from "react-router-dom";

const AddUserBasicInformation = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const navigate = useNavigate();
  const { state } = useLocation();
  const formId = useQuery().get("id");

  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const maxCharactersLength = 255;

  useEffect(() => {
    if (formId) {
      setUpdate(true);
    }
  }, []);

  return (
    <>
      {loading ? (
        <div className="d-flex py-3 justify-content-center align-items-center">
          <Button variant="falcon-default" size="sm" className="fs-10">
            Loading...
          </Button>
        </div>
      ) : (
        <Card.Body
          className=""
          style={{
            height: `calc(100vh - 12.7rem)`,
            overflow: "auto",
            padding: "5px 20px",
          }}
        >
          <Row className="gx-2 pb-2">
            <Col md="6">
              <Form.Group>
                {/* <FloatingLabel controlId="floatingInput" label="Name"> */}
                <Form.Control
                  type="text"
                  placeholder="Code"
                  title="Code"
                  maxLength={maxCharactersLength}
                  className="py-2 "
                  isInvalid={!!errors.code}
                  {...register("code")}
                />
                <div className="d-flex justify-content-between align-items-center">
                  <Form.Control.Feedback
                    type="invalid"
                    className="d-inline w-75"
                  >
                    {errors.code?.message}
                  </Form.Control.Feedback>
                  {/* <small className="fs-10 text-muted">
                  {`${titleWatch?.length || 0} / ${maxCharactersLength}`}
                </small> */}
                </div>
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Group>
                {/* <FloatingLabel controlId="floatingInput" label="Name"> */}
                <Form.Control
                  type="text"
                  placeholder="Login Id"
                  title="Login Id"
                  maxLength={maxCharactersLength}
                  className="py-2 "
                  isInvalid={!!errors.loginId}
                  {...register("loginId")}
                />
                <div className="d-flex justify-content-between align-items-center">
                  <Form.Control.Feedback
                    type="invalid"
                    className="d-inline w-75"
                  >
                    {errors.loginId?.message}
                  </Form.Control.Feedback>
                  {/* <small className="fs-10 text-muted">
                  {`${authorWatch?.length || 0} / ${maxCharactersLength}`}
                </small> */}
                </div>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                {/* <FloatingLabel controlId="floatingInput" label="Name"> */}
                <Form.Control
                  type="text"
                  placeholder="First Name"
                  title="First Name"
                  maxLength={maxCharactersLength}
                  className="py-2 "
                  isInvalid={!!errors.firstName}
                  {...register("firstName")}
                />
                <div className="d-flex justify-content-between align-items-center">
                  <Form.Control.Feedback
                    type="invalid"
                    className="d-inline w-75"
                  >
                    {errors.firstName?.message}
                  </Form.Control.Feedback>
                  {/* <small className="fs-10 text-muted">
                  {`${authorWatch?.length || 0} / ${maxCharactersLength}`}
                </small> */}
                </div>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                {/* <FloatingLabel controlId="floatingInput" label="Name"> */}
                <Form.Control
                  type="text"
                  placeholder="Last Name"
                  title="Last Name"
                  maxLength={maxCharactersLength}
                  className="py-2 "
                  isInvalid={!!errors.lastName}
                  {...register("lastName")}
                />
                <div className="d-flex justify-content-between align-items-center">
                  <Form.Control.Feedback
                    type="invalid"
                    className="d-inline w-75"
                  >
                    {errors.lastName?.message}
                  </Form.Control.Feedback>
                  {/* <small className="fs-10 text-muted">
                  {`${authorWatch?.length || 0} / ${maxCharactersLength}`}
                </small> */}
                </div>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                {/* <FloatingLabel controlId="floatingInput" label="Name"> */}
                <Form.Control
                  type="email"
                  placeholder="Email"
                  title="Email"
                  maxLength={maxCharactersLength}
                  className="py-2 "
                  isInvalid={!!errors.email}
                  {...register("email")}
                />
                <div className="d-flex justify-content-between align-items-center">
                  <Form.Control.Feedback
                    type="invalid"
                    className="d-inline w-75"
                  >
                    {errors.email?.message}
                  </Form.Control.Feedback>
                  {/* <small className="fs-10 text-muted">
                  {`${authorWatch?.length || 0} / ${maxCharactersLength}`}
                </small> */}
                </div>
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Group>
                <Form.Select
                  aria-label="gender"
                  type="text"
                  title="Gender"
                  className="py-2"
                  isInvalid={!!errors?.gender}
                  {...register("gender")}
                >
                  <option value="">Select Geneder</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors?.gender?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Group>
                {/* <FloatingLabel controlId="floatingInput" label="Name"> */}
                <Form.Control
                  type="date"
                  placeholder="Date of Birth"
                  title="Date of Birth"
                  className="py-2 mb-1"
                  isInvalid={!!errors.dateOfBirth}
                  // value={moment.utc(state?.postDate).format("YYYY-MM-DD")}
                  // defaultValue={state?.postDate.split("T")[0]}
                  {...register("dateOfBirth")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.dateOfBirth?.message}
                </Form.Control.Feedback>
                {/* </FloatingLabel> */}
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                {/* <FloatingLabel controlId="floatingInput" label="Name"> */}
                <Form.Control
                  type="password"
                  placeholder="Password"
                  title="Password"
                  className="py-2"
                  isInvalid={!!errors.password}
                  {...register("password")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password?.message}
                </Form.Control.Feedback>
                {/* </FloatingLabel> */}
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                {/* <FloatingLabel controlId="floatingInput" label="Name"> */}
                <Form.Control
                  type="text"
                  placeholder="Password Salt"
                  title="Password Salt"
                  className="py-2 mb-1"
                  isInvalid={!!errors.passwordSalt}
                  {...register("passwordSalt")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.passwordSalt?.message}
                </Form.Control.Feedback>
                {/* </FloatingLabel> */}
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Group>
                <Form.Select
                  aria-label="roleId"
                  type="text"
                  title="Role ID"
                  className="py-2"
                  isInvalid={!!errors?.roleId}
                  {...register("roleId")}
                >
                  <option value="">Select Role</option>
                  <option value="admin">Admin</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors?.roleId?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      )}
    </>
  );
};

export default AddUserBasicInformation;

// <Col md="6">
// <div className="d-flex justify-content-between">
//   <div className="d-flex gap-3">
//     <div {...getRootProps()}>
//       <input {...getInputProps({ multiple: false })} />
//       <Flex
//         justifyContent="center"
//         alignItems="center"
//         // className="px-4"
//       >
//         <FaImage
//           size={27}
//           title="Image"
//           className="mt-2 cursor-pointer mr-1"
//         />
//         {/* <p className="fs-9 mb-0 text-700"> Select Logo</p> */}
//       </Flex>
//     </div>

//     {/* <Col md={file || (state?.logoFile?.fileBase64 ? "2" : "1")}> */}
//     {file || state?.backgroundImageFile?.fileBase64 ? (
//       <FalconLightBox
//         image={
//           file
//             ? file.preview
//             : `data:image/${state.backgroundImageFile.fileBase64
//                 .split(".")
//                 .pop()};base64,${
//                 state.backgroundImageFile.fileBase64
//               }`
//         }
//       >
//         <Image
//           src={
//             file
//               ? file.preview
//               : `data:image/${state.backgroundImageFile.fileBase64
//                   .split(".")
//                   .pop()};base64,${
//                   state.backgroundImageFile.fileBase64
//                 }`
//           }
//           rounded
//           width={80}
//           height={40}
//         />
//       </FalconLightBox>
//     ) : null}
//     {/* Render nothing if both file and state.logoFile.fileBase64 are not present */}
//   </div>
//   <div className="d-flex gap-2">
//     <Form.Check
//       type="checkbox"
//       id="defaultSwitch"
//       label="Is Published"
//       className="mt-2"
//       defaultChecked={!state}
//       isInvalid={!!errors.isPublished}
//       {...register("isPublished")}
//     />
//     <Form.Check
//       type="checkbox"
//       id="defaultSwitch"
//       label="Active"
//       className="mt-2"
//       defaultChecked={!state}
//       isInvalid={!!errors.status}
//       {...register("status")}
//     />
//   </div>
// </div>
// </Col>
