export let customSettings = {
  captchaOn: false,
  toastPosition: "top-right",
  toastClose: 5000,
  currencySign: "€",
};

export let PLANS = {
  BASE: "Basic",
  PREMIUM: "Premium (-10% Soldes)",
  ULTIMATE: "Ultimate (-20% Soldes)",
};

// export const BASEURL = "https://api.mp.integratorsx.com";
export const BASEURL = process.env.REACT_APP_URL;
