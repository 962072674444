import React, { useEffect, useState } from "react";
import { Button, Card, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import ContactsInformation from "./ContactsInformation";
import { useNavigate } from "react-router-dom";
import useQuery from "hooks/useQuery";
import { useLocation } from "react-router-dom";
import AddPermissionsBasicInformation from "./AddPermissionsBasicInformation";

const AddRoleBasicInformation = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const navigate = useNavigate();
  const { state } = useLocation();
  const formId = useQuery().get("id");

  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const maxCharactersLength = 255;

  useEffect(() => {
    if (formId) {
      setUpdate(true);
    }
  }, []);

  return (
    <>
      {loading ? (
        <div className="d-flex py-3 justify-content-center align-items-center">
          <Button variant="falcon-default" size="sm" className="fs-10">
            Loading...
          </Button>
        </div>
      ) : (
        <Card.Body
          className=""
          style={{
            height: `calc(100vh - 8.7rem)`,
            overflow: "auto",
            padding: "5px 20px",
          }}
        >
          <Row className="gx-2 pb-2">
            <Col md="6">
              <Form.Group>
                {/* <FloatingLabel controlId="floatingInput" label="Name"> */}
                <Form.Control
                  type="text"
                  placeholder="Name"
                  title="Name"
                  maxLength={maxCharactersLength}
                  className="py-2 "
                  isInvalid={!!errors.name}
                  {...register("name")}
                />
                <div className="d-flex justify-content-between align-items-center">
                  <Form.Control.Feedback
                    type="invalid"
                    className="d-inline w-75"
                  >
                    {errors.name?.message}
                  </Form.Control.Feedback>
                  {/* <small className="fs-10 text-muted">
                  {`${titleWatch?.length || 0} / ${maxCharactersLength}`}
                </small> */}
                </div>
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Group>
                {/* <FloatingLabel controlId="floatingInput" label="Name"> */}
                <Form.Control
                  type="text"
                  placeholder="Role Id"
                  title="Role Id"
                  maxLength={maxCharactersLength}
                  className="py-2 "
                  isInvalid={!!errors.roleId}
                  {...register("roleId")}
                />
                <div className="d-flex justify-content-between align-items-center">
                  <Form.Control.Feedback
                    type="invalid"
                    className="d-inline w-75"
                  >
                    {errors.roleId?.message}
                  </Form.Control.Feedback>
                  {/* <small className="fs-10 text-muted">
                  {`${authorWatch?.length || 0} / ${maxCharactersLength}`}
                </small> */}
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row className="gx-2 pb-2">
            <AddPermissionsBasicInformation />
          </Row>
        </Card.Body>
      )}
    </>
  );
};

export default AddRoleBasicInformation;
