import { useQuery } from "@tanstack/react-query";
import { CategoriesAPI } from "api";

const fetchCategories = () => {
  return CategoriesAPI.get();
};
export const useFetchCategories = () => {
  return useQuery({
    queryKey: ["categories"],
    queryFn: fetchCategories,
    refetchOnWindowFocus: false, // Disable refetching on window focus
  });
};
