import { UpdateEstimatesAPI, UpdateSettingsAPI } from "api";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { customSettings } from "utils/customConfig";
import * as yup from "yup";
import GeneralInformation from "./GeneralInformation";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object({
  name: yup.string(),
  value: yup.string(),
  caption: yup.string(),
  dataType: yup.string(),
  sortOrder: yup.string(),
});

const GeneralDetail = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);

  const submittedValues = {};
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });
  const {
    handleSubmit,
    reset,
    setValue,
    register,
    control,
    watch,
    clearErrors,
    getValues,
    setError,
    formState: { errors },
  } = methods;

  const handleValidation = (value) => {
    let errorMessage = "";

    // Get the dataType from the state to apply conditional validation
    const dataType = state?.dataType;

    switch (dataType) {
      case "EMAIL":
        if (!value) {
          errorMessage = "Email is required";
        } else {
          const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zAZ0-9.-]+\.[a-zA-Z]{2,}$/;
          if (!emailRegex.test(value)) {
            errorMessage = "Invalid email format";
          }
        }
        break;
      case "NUMBER":
        if (isNaN(value)) {
          errorMessage = "Value must be a number";
        }
        break;
      case "BOOLEAN":
        if (value !== "true" && value !== "false") {
          errorMessage = "Value must be true or false";
        }
        break;
      case "DATE":
        if (isNaN(new Date(value).getTime())) {
          errorMessage = "Invalid date format";
        }
        break;
      default:
        if (!value) {
          errorMessage = "Value is required";
        }
    }

    // Set error message if validation fails
    if (errorMessage) {
      setError("value", { type: "manual", message: errorMessage });
    } else {
      clearErrors("value");
    }
  };

  const onSubmit = async (data) => {
    handleValidation(data.value);

    if (errors.value) {
      // If there is any error, prevent submission
      return;
    }

    let myData = [
      {
        ...data,
      },
    ];

    try {
      if (state) {
        await UpdateSettingsAPI.post(myData);
        toast.success("Category Group Updated Successfully", {
          position: customSettings.toastPosition,
          autoClose: customSettings.toastClose,
        });
      } else {
        await UpdateSettingsAPI.post(myData);
        toast.success("Category Group Created Successfully", {
          position: customSettings.toastPosition,
          autoClose: customSettings.toastClose,
        });
      }

      // ------- Get all object keys form data and set empty values to reset ------------
      const keys = Object.keys(data);
      for (const key of keys) {
        submittedValues[key] = "";
      }
      reset({ ...submittedValues });
      navigate(-1);
    } catch (error) {
      toast.error("Something Went Wrong", {
        position: customSettings.toastPosition,
        autoClose: customSettings.toastClose,
      });
      console.log(error);
    }
  };

  // Watch the value of the 'value' field and validate it on change
  const watchedValue = watch("value");
  useEffect(() => {
    if (watchedValue) {
      handleValidation(watchedValue); // Trigger inline validation on value change
    }
  }, [watchedValue]);

  useEffect(() => {
    if (state) {
      reset(state);
    }
  }, [state, reset]);

  return (
    <>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Card>
            <Card.Header className="border-bottom">
              <Row className="flex-between-center ">
                <Col md>
                  <h5 className="fs-9 text-nowrap mb-2 mb-md-0">
                    {" "}
                    Settings | General
                  </h5>
                </Col>
                <Col xs="auto">
                  <Button
                    variant="link"
                    className="text-secondary fw-medium p-0 me-3 "
                    type="button"
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    type="submit"
                    // onClick={handleSubmit}
                  >
                    Ok
                  </Button>
                </Col>
              </Row>
            </Card.Header>
            <>
              {loading ? (
                <div className="d-flex py-3 justify-content-center align-items-center">
                  <Button variant="falcon-default" size="sm" className="fs-10">
                    Loading...
                  </Button>
                </div>
              ) : (
                <Card.Body
                  className=""
                  style={{
                    height: `calc(100vh - 12.7rem)`,
                    overflow: "auto",
                    padding: "5px 20px",
                  }}
                >
                  <Row className="gx-2 pb-2">
                    <Col md="6">
                      <Form.Group>
                        <Form.Control
                          type="text"
                          placeholder="Name"
                          title="Name"
                          className="py-2 "
                          disabled
                          isInvalid={!!errors.name}
                          {...register("caption")}
                        />
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group>
                        <Controller
                          name="value"
                          control={control}
                          render={({ field }) => (
                            <Form.Control
                              {...field}
                              placeholder="Value"
                              className="py-2 "
                              isInvalid={!!errors.value}
                              onBlur={(e) => handleValidation(e.target.value)} // Trigger validation on blur
                              onChange={(e) => {
                                field.onChange(e);
                                handleValidation(e.target.value); // Trigger validation on change
                              }}
                            />
                          )}
                        />
                        {errors.value && (
                          <Form.Text className="text-danger">
                            {errors.value.message}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              )}
            </>
          </Card>
        </Form>
      </FormProvider>
    </>
  );
};

export default GeneralDetail;
