import React, { useEffect, Fragment, useState, createElement } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Nav, Navbar, Row, Col, Spinner } from "react-bootstrap";
import { navbarBreakPoint, topNavbarBreakpoint } from "config";
import Flex from "components/common/Flex";
import Logo from "components/common/Logo";
import NavbarVerticalMenu from "./NavbarVerticalMenu";
import ToggleButton from "./ToggleButton";
import routes from "routes/siteMaps";
import { capitalize } from "helpers/utils";
import NavbarTopDropDownMenus from "components/navbar/top/NavbarTopDropDownMenus";
import PurchaseCard from "./PurchaseCard";
import bgNavbar from "assets/img/generic/bg-navbar.png";
import { useAppContext } from "Main";
import { MenusAPI, RolePermissionsAPI } from "api";
import {
  filterAndSortMenuItems,
  useFilterAndSortMenuItems,
} from "utils/menuUtils";

import * as ReactIcons from "react-icons/bs";
import { FaWpforms } from "react-icons/fa";
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";

const NavbarVertical = () => {
  const [menuState, setMenuState] = useState([]);
  const [permissionsData, setPermissionsData] = useState([]);
  const [hover, setHover] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [bgColor, setBgColor] = useState("#fff"); // Default background color

  const { nestedMenuItems } = useFilterAndSortMenuItems();

  // console.log(nestedMenuItems);

  let structuredMenu = nestedMenuItems?.map((d) => ({
    name: d.name,
    active: true,
    icon: ReactIcons[d.icon] ? createElement(ReactIcons[d.icon]) : null,
    children: d.children.map((s) => ({
      name: s.name,
      icon: ReactIcons[s.icon] ? createElement(ReactIcons[s.icon]) : null,
      to: s.url,
      exact: true,
      active: true,
    })),
  }));

  // console.log(structuredMenu);

  let structuredMenuLocal = [
    // {
    //   name: "Blog",
    //   icon: createElement(ReactIcons["BsLayoutTextSidebar"]),
    //   active: true,
    //   children: [
    //     {
    //       name: "Posts",
    //       to: "/blog/posts",
    //       icon: createElement(ReactIcons["BsLayoutTextSidebar"]),
    //       exact: true,
    //       active: true,
    //     },
    //   ],
    // },
    // {
    //   name: "Customers",
    //   icon: createElement(ReactIcons["BsLayoutTextSidebar"]),
    //   to: "/customers",
    //   exact: true,
    //   active: true,
    // },
    // {
    //   name: "Estimates",
    //   icon: createElement(ReactIcons["BsLayoutTextSidebar"]),
    //   to: "/estimates",
    //   exact: true,
    //   active: true,
    // },
    // {
    //   name: "Get an Estimate ",
    //   icon: createElement(ReactIcons["BsLayoutTextSidebar"]),
    //   to: "/home/get-an-estimate",
    //   exact: true,
    //   active: true,
    // },
  ];

  let combinedMenu = [...(structuredMenu || []), ...structuredMenuLocal];

  const {
    config: {
      navbarPosition,
      navbarStyle,
      isNavbarVerticalCollapsed,
      showBurgerMenu,
    },
    setConfig,
  } = useAppContext();

  const HTMLClassList = document.getElementsByTagName("html")[0].classList;

  useEffect(() => {
    if (isNavbarVerticalCollapsed) {
      HTMLClassList.add("navbar-vertical-collapsed");
    } else {
      HTMLClassList.remove("navbar-vertical-collapsed");
    }
    return () => {
      HTMLClassList.remove("navbar-vertical-collapsed-hover");
    };
  }, [isNavbarVerticalCollapsed, HTMLClassList]);

  //Control mouseEnter event
  let time = null;
  const handleMouseEnter = () => {
    // console.log("enter");

    setHover(true);
    if (isNavbarVerticalCollapsed) {
      time = setTimeout(() => {
        HTMLClassList.add("navbar-vertical-collapsed-hover");
      }, 100);
    }
  };
  const handleMouseLeave = () => {
    setHover(false);
    clearTimeout(time);
    HTMLClassList.remove("navbar-vertical-collapsed-hover");
  };

  const handleClick = () => {
    setCollapsed(!collapsed);
    document
      .getElementsByTagName("html")[0]
      .classList.toggle("navbar-vertical-collapsed");
    setConfig("isNavbarVerticalCollapsed", !isNavbarVerticalCollapsed);
  };

  useEffect(() => {
    const handleShortcut = (e) => {
      if (e.key === "[") {
        handleClick();
      }
    };

    // Add the event listener on mount
    window.addEventListener("keydown", handleShortcut);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("keydown", handleShortcut);
    };
  }, []);

  const NavbarLabel = ({ label }) => (
    <Nav.Item as="li">
      <Row className="mt-3 mb-2 navbar-vertical-label-wrapper">
        <Col xs="auto" className="navbar-vertical-label navbar-vertical-label">
          {label}
        </Col>
        <Col className="ps-0">
          <hr className="mb-0 navbar-vertical-divider"></hr>
        </Col>
      </Row>
    </Nav.Item>
  );

  return (
    <Navbar
      expand={navbarBreakPoint}
      className={classNames("navbar-vertical", {
        [`navbar-${navbarStyle}`]: navbarStyle !== "transparent",
      })}
      variant="light"
    >
      <Flex alignItems="center" className="navbar-large">
        {/* <Flex alignItems="center"> */}
        <ToggleButton />
        <div
          className="fs-8 fw-medium text-primary navbar-logo"
          // style={{ marginLeft: "-18px" }}
        >
          <div className="d-flex align-items-center">
            {/* <Logo
              at="navbar-vertical"
              textClass="text-primary fs-8"
              width={24}
            /> */}
            <span style={{ marginLeft: "-18px" }}>NexusWare</span>
          </div>
        </div>
      </Flex>
      <Navbar.Collapse
        in={showBurgerMenu}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          backgroundImage:
            navbarStyle === "vibrant"
              ? `linear-gradient(-45deg, rgba(0, 160, 255, 0.86), #0048a2),url(${bgNavbar})`
              : "none",
          marginTop: "0px",
          backgroundColor: "#E6F1EE",
          borderRight: "2px solid #E6F1EE",
        }}
      >
        <div
          className="navbar-vertical-content scrollbar"
          // style={{ backgroundColor: "#e8f0fc", paddingTop: "0px" }}
          style={{ paddingTop: "0px", borderRight: "1px solid #E6F1EE" }}
        >
          {/* <Nav className="flex-column" as="ul">
            {routes.map((route) => (
              <Fragment key={route.label}>
                {!route.labelDisable && (
                  <NavbarLabel label={capitalize(route.label)} />
                )}
                <NavbarVerticalMenu routes={route.children} />
              </Fragment>
            ))}
          </Nav> */}

          <Nav className="flex-column" key="">
            <Fragment>
              {hover && (
                <div
                  style={{
                    position: "absolute",
                    top: "10%",
                    width: "auto",
                    right: -15,
                    zIndex: 99999999,

                    // backgroundColor: "#fff",
                    // border: "none",
                    // borderRadius: "100%",
                    cursor: "pointer",
                    opacity: hover ? 1 : 0, // Control opacity based on hover state
                    transition: "opacity 0.5s ease-in-out", // Smooth transition for opacity
                  }}
                  onClick={handleClick}
                >
                  {collapsed ? (
                    <IoIosArrowDropright
                      title="Expand ["
                      size={30}
                      className="collapse-icon-custome"
                      style={{ borderRadius: "100%" }}
                    />
                  ) : (
                    <IoIosArrowDropleft
                      title="Collapse ["
                      size={30}
                      className="collapse-icon-custome"
                      style={{ borderRadius: "110%" }}
                    />
                  )}
                </div>
              )}

              {/* {isLoading && <Spinner variant="primary" animation="border" />} */}
              {/* {!route.labelDisable && <NavbarLabel label={route.name} />} */}
              <h6>
                <NavbarVerticalMenu routes={combinedMenu} />
              </h6>
            </Fragment>
          </Nav>

          <>
            {navbarPosition === "combo" && (
              <div className={`d-${topNavbarBreakpoint}-none`}>
                <div className="navbar-vertical-divider">
                  <hr className="navbar-vertical-hr my-2" />
                </div>
                <Nav navbar>
                  <NavbarTopDropDownMenus />
                </Nav>
              </div>
            )}
            {/* <PurchaseCard /> */}
          </>
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
};

NavbarVertical.propTypes = {
  label: PropTypes.string,
};

export default NavbarVertical;
