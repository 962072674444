import styled from "styled-components";
import "./styles.css";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AgGridReact } from "ag-grid-react"; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import {
  Button,
  Card,
  Dropdown,
  Form,
  OverlayTrigger,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import {
  clearLocalStorage,
  getLocalStorage,
  setLocalStorage,
} from "./utils/StoreState";
import BreadCrumb from "components/breadcrumb/BreadCrumb";
import IconButton from "components/common/IconButton";
import { useNavigate } from "react-router-dom";
import CardDropdown from "components/common/CardDropdown";
import { MdDeleteOutline, MdOutlineModeEdit } from "react-icons/md";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import TableHeaderDropdown from "components/common/TableHeaderDropdown";
import { LibraryAPI, ProductsAPI } from "api";
import { Checkbox } from "@mui/material";
import PageTitle from "utils/PageTitle";
import { CONSTANT_ROUTES } from "routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { settings } from "config";
import {
  resetColumnState,
  restoreColumnState,
  saveColumnState,
} from "utils/tablesUtils";

export default function ProductsTable() {
  let isDarkBool = localStorage.getItem("isDark") === true;
  let isDarkTheme = localStorage.getItem("theme") === "dark";
  let auto = localStorage.getItem("theme") === "auto";
  const navigate = useNavigate();
  const columnApiRef = useRef();
  const gridRef = useRef();
  // let gridApi;

  const [rowData, setRowData] = useState([
    // { id: "1", type: "ABC", name: "Model Y" },
  ]);
  const [isLoading, setIsLoading] = useState(false);

  // Column Definitions: Defines the columns to be displayed.
  const [colDefs, setColDefs] = useState([
    // { field: "", filter: false, headerName: "Select" },

    { field: "name", headerName: "Name", hide: false, flex: 9 },
    {
      field: "active",
      headerName: "Active",
      hide: false,
      flex: 1.5,
      cellStyle: {
        display: "flex",
        // paddingRight: "30px",
        justifyContent: "center",
      },
      filter: false,

      headerClass: "center-header",
      cellRenderer: (p) => (
        <Form.Check
          type="checkbox"
          id="statusSwitch"
          style={{ padding: "10px 0px" }}
          checked={p?.data.status}
        />
      ),
    },
    {
      headerName: "Action",
      field: "action",
      flex: 1.5,
      filter: false,

      headerClass: "center-header",
      cellStyle: {
        display: "flex",
        // paddingRight: "30px",
        justifyContent: "center",
      },
      // cellStyle: {
      //   display: "flex",
      //   justifyContent: "center",
      //   alignItems: "center",
      // },
      cellRenderer: (p) => (
        <CardDropdown>
          <div className="py-2">
            <Dropdown.Item
              // as={Link}
              onClick={() =>
                // navigate(`/libraries/categories/add-new-category/?id=${p.data.id}`)
                // () =>
                handleRowClick(p.data)
              }
              // href="/products/add-product"
              className="d-flex align-items-center gap-2 py-0"
            >
              <MdOutlineModeEdit />
              Edit
            </Dropdown.Item>
            {/* <Dropdown.Item
              // onClick={() => handleDelete()}
              className="d-flex align-items-center gap-2 py-0"
            >
              <MdDeleteOutline />
              Delete
            </Dropdown.Item> */}
          </div>
        </CardDropdown>
      ),
    },
  ]);

  const handleRowClick = (data) => {
    if (data) {
      navigate(`${CONSTANT_ROUTES.PRODUCTS_ADD}`, { state: data });
    }
  };

  const defaultColDef = {
    sortable: true,
    filter: true,
    // floatingFilter: true,
    // flex: 1,
    // rowDrag: true,
  };

  //   ===============Custom Api Call start================

  let gridApi;
  let columnApi;

  const onGridReady = useCallback(
    (e) => {
      gridApi = e.api;
      columnApi = e.columnApi;
      restoreState();

      ProductsAPI.get().then((res) => {
        e.api.applyTransaction({ add: res?.data?.data });
      });
    },
    [window]
  );

  //   ===============Custom Api Call End================

  const saveState = useCallback(() => {
    saveColumnState("products", columnApiRef);
  }, []);

  const restoreState = useCallback(() => {
    restoreColumnState("products", columnApiRef);
  }, []);

  const resetState = useCallback(() => {
    resetColumnState("products", columnApiRef);
  }, []);

  const onFilterTextBoxChanged = useCallback(() => {
    columnApiRef.current.api.setGridOption(
      "quickFilterText",
      document.getElementById("filter-text-box").value
    );
  }, []);

  const onRowDoubleClick = (params) => {
    navigate(`/categories/add-new-category/?id=${params.data?.type}`);
  };

  if (isLoading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgba(0, 0, 0, 0.5)", // Backdrop effect
          zIndex: 9999, // Ensure it's above other content
        }}
      >
        <Spinner variant="primary" animation="border" />
      </div>
    );
  }

  return (
    <div>
      {/* <PageTitle name="Categories" /> */}
      {/* <div className="py-2">
        <BreadCrumb />
      </div> */}

      <div
        className={`${
          isDarkBool || isDarkTheme || auto
            ? "ag-theme-quartz-dark"
            : "ag-theme-quartz"
        } rounded-0`} // applying the grid theme
        // style={{ height: "100vh" }} // the grid will fill the size of the parent container
      >
        <Card style={{ height: `calc(100vh - 4rem)`, overflow: "auto" }}>
          <Card.Header className="d-flex justify-content-between align-items-center">
            <h5 className="fs-9 mb-0 text-nowrap py-2 py-xl-0">
              Products | Manage Products
            </h5>
            <div className="d-flex align-items-center gap-2">
              <Form className="position-relative">
                <FontAwesomeIcon
                  icon="search"
                  className="position-absolute text-400 search-box-icon"
                  style={{
                    left: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                />
                <Form.Control
                  type="search"
                  placeholder="Search..."
                  style={{
                    paddingLeft: "30px",
                  }}
                  aria-label="Search"
                  id="filter-text-box"
                  className="rounded-pill search-input"
                  onInput={onFilterTextBoxChanged}
                />
              </Form>
              <OverlayTrigger
                overlay={
                  <Tooltip
                    style={{ position: "fixed" }}
                    id="overlay-trigger-example"
                  >
                    New
                  </Tooltip>
                }
              >
                <IconButton
                  variant="falcon-default"
                  size="sm"
                  icon="plus"
                  transform="shrink-3"
                  onClick={() => navigate(CONSTANT_ROUTES.PRODUCTS_ADD)}
                >
                  <span className="d-none d-sm-inline-block ms-1">New</span>
                </IconButton>
              </OverlayTrigger>
              <TableHeaderDropdown>
                <div className="py-2">
                  <Dropdown.Item
                    onClick={saveState}
                    className="d-flex align-items-center gap-2 py-2"
                  >
                    Save State
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={restoreState}
                    className="d-flex align-items-center gap-2 py-2"
                  >
                    Restore State
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={resetState}
                    className="d-flex align-items-center gap-2 py-2"
                  >
                    Reset State
                  </Dropdown.Item>
                </div>
              </TableHeaderDropdown>
            </div>
            {/* <div className="test-header">
              <div className="example-header">
                <input
                  type="text"
                  id="filter-text-box"
                  placeholder="Search"
                  onInput={onFilterTextBoxChanged}
                />
              </div>
              <div className="example-section">
                <button onClick={saveState}>Save State</button>
                <button onClick={restoreState}>Restore State</button>
                <button onClick={resetState}>Reset State</button>
              </div>
            </div> */}
          </Card.Header>

          <Card.Body className="p-0">
            {/* <button onClick={() => onExportClick()}>abc</button> */}

            <AgGridReact
              // rowData={rowData}
              ref={columnApiRef}
              onGridReady={onGridReady}
              columnDefs={colDefs}
              defaultColDef={defaultColDef}
              pagination
              headerHeight={35}
              rowHeight={35}
              paginationPageSize={settings.paginationSize}
              onRowDoubleClicked={(params) => handleRowClick(params.data)}
              overlayNoRowsTemplate="Nothing found - Looks empty over here"
            />
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}
