import { SliderImagesAPI, UpdateEstimatesAPI, UpdateSettingsAPI } from "api";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { customSettings } from "utils/customConfig";
import * as yup from "yup";
import SliderInformation from "./SliderInformation";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object({
  caption: yup.string(),
  altText: yup.string(),
  url: yup.string(),
  animation: yup.string(),
  description: yup.string(),
  imageUrl: yup.string(),
  sortOrder: yup.string(),
  status: yup.boolean(),
});

const AddSlider = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);

  const submittedValues = {};
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });
  const {
    handleSubmit,
    reset,
    setValue,
    register,
    control,
    watch,
    clearErrors,
    getValues,
    setError,
    formState: { errors },
  } = methods;

  const onSubmit = async (data) => {
    let myData = {
      ...data,
    };

    console.log(myData);
    try {
      if (state) {
        await SliderImagesAPI.post(myData);
        toast.success("Slider Updated Successfully", {
          position: customSettings.toastPosition,
          autoClose: customSettings.toastClose,
        });
      } else {
        await SliderImagesAPI.post(myData);
        toast.success("Slider Created Successfully", {
          position: customSettings.toastPosition,
          autoClose: customSettings.toastClose,
        });
      }

      // ------- Get all object keys form data and set empty values to reset ------------
      const keys = Object.keys(data);
      for (const key of keys) {
        submittedValues[key] = "";
      }
      reset({ ...submittedValues });
      navigate(-1);
    } catch (error) {
      toast.error("Something Went Wrong", {
        position: customSettings.toastPosition,
        autoClose: customSettings.toastClose,
      });
      console.log(error);
    }
  };

  useEffect(() => {
    if (state) {
      reset(state);
    }
  }, [state, reset]);

  return (
    <>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Card>
            <Card.Header className="border-bottom">
              <Row className="flex-between-center ">
                <Col md>
                  <h5 className="fs-9 text-nowrap mb-2 mb-md-0">
                    {" "}
                    Slider Images
                  </h5>
                </Col>
                <Col xs="auto">
                  <Button
                    variant="link"
                    className="text-secondary fw-medium p-0 me-3 "
                    type="button"
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    type="submit"
                    // onClick={handleSubmit}
                  >
                    Ok
                  </Button>
                </Col>
              </Row>
            </Card.Header>
            <SliderInformation />
          </Card>
        </Form>
      </FormProvider>
    </>
  );
};

export default AddSlider;
