import { Checkbox } from "@mui/material";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { AgGridReact } from "ag-grid-react"; // AG Grid Component
import {
  PagesAPI,
  CategoryFieldSetsAPI,
  CustomersAPI,
  EstimatesAPI,
  VariantsAPI,
} from "api";
import BreadCrumb from "components/breadcrumb/BreadCrumb";
import CardDropdown from "components/common/CardDropdown";
import IconButton from "components/common/IconButton";
import TableHeaderDropdown from "components/common/TableHeaderDropdown";
import React, { useCallback, useRef, useState } from "react";
import { Card, Dropdown, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { MdOutlineModeEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { CONSTANT_ROUTES } from "routes";
import "./styles.css";
import {
  clearLocalStorage,
  getLocalStorage,
  setLocalStorage,
} from "./utils/StoreState";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { settings } from "config";
import {
  resetColumnState,
  restoreColumnState,
  saveColumnState,
} from "utils/tablesUtils";
import { useTab } from "./context/TabContext";

export default function CustomersTable() {
  let isDarkBool = localStorage.getItem("isDark") === true;
  let isDarkTheme = localStorage.getItem("theme") === "dark";
  let auto = localStorage.getItem("theme") === "auto";
  const navigate = useNavigate();
  const columnApiRef = useRef();
  const gridRef = useRef();
  const { key, setKey } = useTab();
  // let gridApi;

  const [rowData, setRowData] = useState([
    // { id: "1", type: "ABC", name: "Model Y" },
  ]);

  // Column Definitions: Defines the columns to be displayed.
  const [colDefs, setColDefs] = useState([
    // { field: "", filter: false, headerName: "Select" },

    // {
    //   field: "type",
    //   headerName: "Type",

    //   hide: false,
    // },
    {
      field: "name",
      headerName: "Name",
      hide: false,
      flex: 3.5,
      cellRenderer: (params) => {
        const { firstName, lastName } = params.data;
        return `${firstName || ""} ${lastName || ""}`.trim(); // Handle undefined or null values
      },
    },
    {
      field: "emailAddress",
      headerName: "Email Address",
      hide: false,
      flex: 3.5,
    },
    // {
    //   field: "firstName",
    //   headerName: "First Name",
    //   hide: false,
    //   flex: 2.5,
    //   // cellRenderer: (params) => {
    //   //   const { firstName, lastName } = params.data;
    //   //   return `${firstName || ""} ${lastName || ""}`.trim(); // Handle undefined or null values
    //   // },
    // },

    {
      field: "address",
      headerName: "Address",
      hide: false,
      flex: 3.5,
      cellRenderer: (params) => {
        const { postalCode, city, streetAddress, state } = params.data;
        return `${city || ""} ${state || ""} ${postalCode || ""}`.trim(); // Handle undefined or null values
      },
    },
    // {
    //   field: "postalCode",
    //   headerName: "Postal Code",
    //   hide: false,
    //   flex: 1.5,
    // },
    // {
    //   field: "city",
    //   headerName: "City",
    //   hide: false,
    //   flex: 1.5,
    // },
    {
      field: "status",
      headerName: "Active",
      hide: false,
      filter: false,
      flex: 1.5,
      cellStyle: {
        display: "flex",

        justifyContent: "center",
      },
      headerClass: "center-header",
      cellRenderer: (p) => (
        <Form.Check
          type="checkbox"
          id="statusSwitch"
          style={{ padding: "10px 0px" }}
          checked={p?.data.status}
        />
      ),
    },
    // {
    //   headerName: "Action",
    //   field: "action",
    //   flex: 1.5,
    //   filter: false,
    //   headerClass: "center-header",
    //   cellStyle: {
    //     display: "flex",
    //     justifyContent: "center",
    //   },
    //   cellRenderer: (p) => (
    //     <CardDropdown>
    //       <div className="py-2">
    //         <Dropdown.Item
    //           // as={Link}
    //           onClick={() =>
    //             // navigate(`/libraries/categories/add-new-category/?id=${p.data.id}`)
    //             // () =>
    //             handleRowClick(p.data)
    //           }
    //           // href="/products/add-product"
    //           className="d-flex align-items-center gap-2 py-0"
    //         >
    //           <MdOutlineModeEdit />
    //           Edit
    //         </Dropdown.Item>
    //         {/* <Dropdown.Item
    //           // onClick={() => handleDelete()}
    //           className="d-flex align-items-center gap-2 py-0"
    //         >
    //           <MdDeleteOutline />
    //           Delete
    //         </Dropdown.Item> */}
    //       </div>
    //     </CardDropdown>
    //   ),
    // },
  ]);

  const handleRowClick = (data) => {
    setKey("detail");
    if (data) {
      navigate(`${CONSTANT_ROUTES.CUSTOMER_TABLE_DETAIL}`, { state: data });
    }
  };

  const defaultColDef = {
    sortable: true,
    filter: true,
    // floatingFilter: true,
    // flex: 1,
    // rowDrag: true,
  };

  //   ===============Custom Api Call start================

  let gridApi;
  let columnApi;

  const onGridReady = useCallback(
    (e) => {
      gridApi = e.api;
      columnApi = e.columnApi;
      restoreState();
      CustomersAPI.get().then((res) => {
        e.api.applyTransaction({ add: res?.data?.data });
      });
    },
    [window]
  );

  const saveState = useCallback(() => {
    saveColumnState("customers", columnApiRef);
  }, []);

  const restoreState = useCallback(() => {
    restoreColumnState("customers", columnApiRef);
  }, []);

  const resetState = useCallback(() => {
    resetColumnState("customers", columnApiRef);
  }, []);

  const onFilterTextBoxChanged = useCallback(() => {
    columnApiRef.current.api.setGridOption(
      "quickFilterText",
      document.getElementById("filter-text-box").value
    );
  }, []);

  return (
    <div>
      {/* <div className="py-2">
        <BreadCrumb />
      </div> */}

      <div
        className={`${
          isDarkBool || isDarkTheme || auto
            ? "ag-theme-quartz-dark"
            : "ag-theme-quartz"
        } rounded-0`} // applying the grid theme
        // style={{ height: "100vh" }} // the grid will fill the size of the parent container
      >
        <Card style={{ height: `calc(100vh - 4rem)`, overflow: "auto" }}>
          <Card.Header className="d-flex justify-content-between align-items-center">
            <h5 className="fs-9 mb-0 text-nowrap py-2 py-xl-0"> Customers </h5>
            <div className="d-flex align-items-center gap-2">
              <Form className="position-relative">
                <FontAwesomeIcon
                  icon="search"
                  className="position-absolute text-400 search-box-icon"
                  style={{
                    left: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                />
                <Form.Control
                  type="search"
                  placeholder="Search..."
                  style={{
                    paddingLeft: "30px",
                  }}
                  aria-label="Search"
                  id="filter-text-box"
                  className="rounded-pill search-input"
                  onInput={onFilterTextBoxChanged}
                />
              </Form>
              {/* <OverlayTrigger
                overlay={
                  <Tooltip
                    style={{ position: "fixed" }}
                    id="overlay-trigger-example"
                  >
                    New
                  </Tooltip>
                }
              >
                <IconButton
                  variant="falcon-default"
                  size="sm"
                  icon="plus"
                  transform="shrink-3"
                  onClick={() =>
                    navigate(CONSTANT_ROUTES.CUSTOMER_TABLE_DETAIL)
                  }
                >
                  <span className="d-none d-sm-inline-block ms-1">New</span>
                </IconButton>
              </OverlayTrigger> */}
              <TableHeaderDropdown>
                <div className="py-2">
                  <Dropdown.Item
                    onClick={saveState}
                    className="d-flex align-items-center gap-2 py-2"
                  >
                    Save State
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={restoreState}
                    className="d-flex align-items-center gap-2 py-2"
                  >
                    Restore State
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={resetState}
                    className="d-flex align-items-center gap-2 py-2"
                  >
                    Reset State
                  </Dropdown.Item>
                </div>
              </TableHeaderDropdown>
            </div>
          </Card.Header>

          <Card.Body className="p-0">
            <AgGridReact
              // rowData={rowData}
              ref={columnApiRef}
              pagination
              paginationPageSize={settings.paginationSize}
              onGridReady={onGridReady}
              columnDefs={colDefs}
              headerHeight={35}
              rowHeight={35}
              defaultColDef={defaultColDef}
              onRowDoubleClicked={(params) => handleRowClick(params.data)}
              overlayNoRowsTemplate="Nothing found - Looks empty over here"
            />
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}
